import React, { Component } from 'react';

import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { rootReducer } from './rootReducer';

import Header from './components/Header';
import About from './components/About';
import Resume from './components/Resume';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Footer from './components/Footer';

const store = createStore(rootReducer);

class App extends Component {
	render() {
		return (
			<Provider store={store}>
				<div className="App">
					<Header socials={socials}/>
					<About/>
					<Resume/>
					<Portfolio projects={projects}/>
					<Contact/>
					<Footer socials={socials}/>
				</div>
			</Provider>
		);
	}
}

const socials = [
	{
		"name":"linkedin",
		"url":"https://www.linkedin.com/in/nadir-barlozzo-9179011b0/",
		"className":"fa fa-linkedin"
	},
	{
		"name":"github",
		"url":"http://github.com/lochiQF",
		"className":"fa fa-github"
	}
];

const projects = [
	
	{
		"name":"Atal",
		"type":"Website",
		"img":"images/portfolio/atal-site.png",
		"link":"http://atal-arco.ch/"
	},
	{
		"name":"TTVD",
		"type":"CLI Tool",
		"img":"images/portfolio/ttvd-tool.png",
		"link":"https://github.com/LochiQF/ttvd"
	},
	{
		"name":"Barcoin",
		"type":"Desktop App",  
		"img":"images/portfolio/barcoin-app.png",
		"link":""
	}
]

export default App;