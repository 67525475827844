import React, { Component } from 'react';

export default class Footer extends Component {

	render() {
		const socials = this.props.socials;

		return (
			<footer>
				<div className="row">
					<div className="twelve columns">
						<ul className="social-links">
						{
							socials && socials.map((item)=>{
								return(
									<li>
										<a href={item.url}>
											<i className={item.className} />
										</a>
									</li>
								)
							})
						}
						</ul>			
					</div>
					<p>Favicon by <a target='_blank' href="https://icons8.com/">icons8</a></p>
					<div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i className="icon-up-open" /></a></div>
				</div>
			</footer>
		);
	}
}