import { SET_LANGUAGE } from '../../constants';

export const initialState = {
	locale: 'it_IT',
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SET_LANGUAGE:
			return { 
				...state,
				locale: action.locale
			};
		default:
			return state;
	}
}
