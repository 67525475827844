import React, { Component } from 'react';
import TranslationContainer from './Translation/TranslationContainer';

export default class Resume extends Component {
	
	render() {

		return (
			<section id="resume">	
				<div className="row education">
					<div className="three columns header-col">
						<h1><span><TranslationContainer translationKey="education"/></span></h1>
					</div>
					<div className="nine columns main-col">
						<div className="row item">
							<div className="twelve columns">
								<h3><TranslationContainer translationKey="hSchoolName"/></h3>
								<p className="info">
									<TranslationContainer translationKey="hSpec"/>
									<span>&bull;</span><em className="date"><TranslationContainer translationKey="hMonthPassing"/><TranslationContainer translationKey="hYearPassing"/></em>
								</p>
								<p>
									<TranslationContainer translationKey="hAchievements"/> <a target='_blank' href='https://www.tio.ch/ticino/attualita/1390240/premiate-le-giovani-eccellenze-ict-della-svizzera-italiana'>Tio.ch</a>
								</p>
							</div>
						</div>
						<div className="row item">
							<div className="twelve columns">
								<h3><TranslationContainer translationKey="mSchoolName"/></h3>
								<p className="info">
									<TranslationContainer translationKey="mSpec"/>
									<span>&bull;</span><em className="date"><TranslationContainer translationKey="mMonthPassing"/><TranslationContainer translationKey="mYearPassing"/></em>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="row work">
					<div className="three columns header-col">
						<h1><span><TranslationContainer translationKey="work"/></span></h1>
					</div>
					<div className="nine columns main-col">
						<div className="row item">
							<div className="twelve columns">
								<h3><TranslationContainer translationKey="companyName2"/></h3>
								<p className="info">
									<TranslationContainer translationKey="jobDescription2"/>
									<span>&bull;</span><TranslationContainer translationKey="active"/>
								</p>
								<p>
									<TranslationContainer translationKey="jobAchievements2"/>
								</p>
							</div>
						</div>
						<div className="row item">
							<div className="twelve columns">
								<h3><TranslationContainer translationKey="companyName"/></h3>
								<p className="info">
									<TranslationContainer translationKey="jobDescription"/>
									<span>&bull;</span><em className="date"><TranslationContainer translationKey="monthLeaving"/><TranslationContainer translationKey="yearLeaving"/></em>
								</p>
								<p>
									<TranslationContainer translationKey="jobAchievements"/>
								</p>
							</div>
						</div>						
					</div>
				</div>
			</section>
		);
	}
}