import React, { Component } from 'react';
import TranslationContainer from './Translation/TranslationContainer';

export default class Porfolio extends Component {

	render() {
		let projects = this.props.projects;

		return (
			<section id="portfolio">
				<div className="row">
					<div className="twelve columns collapsed">
						<h1><TranslationContainer translationKey="checkOut"/></h1>
						<div id="portfolio-wrapper" className="bgrid-thirds s-bgrid-thirds cf">
						{
							projects.map((item)=> {
								return (
									<a target='_blank' href={ item.link !== '' && item.link }>
										<div className="columns portfolio-item">
											<div className="item-wrap">
												<img src={ item.img } style={{ opacity: '0.9' }} className="item-img"/>
												<div className="overlay">
													<div className="portfolio-item-meta">
														<h5 style={{ color: '#11ABB0' }}>{ item.name }</h5>
														<p>{ item.type }</p>
													</div>
												</div>
											</div>
										</div>
									</a>
								)
							})
						}
						</div>
					</div>
				</div>
			</section>
		);
	}
}