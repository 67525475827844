import React, { Component } from 'react';
import TranslationContainer from './Translation/TranslationContainer';

export default class About extends Component {
	
	render() {		
		return (
			<section id="about">
				<div className="row">
					<div className="three columns">
						<img style={{display: 'none'}} className="profile-pic" src="images/profilepic.jpg" alt="" />
					</div>
					<div className="five columns">
						<h2><TranslationContainer translationKey="navAbout"/></h2>
						<p><TranslationContainer translationKey="aboutMe"/></p>
					</div>
					<div className="four columns">	
						<h2>Hobby</h2>
						<p><TranslationContainer translationKey="hobby"/></p>
					</div>
				</div>
			</section>
		);
	}
}