import React, { Component } from 'react';
import { LANG_NAMES } from '../../constants/translations';
import PropTypes from 'prop-types';

export default class LangSwitch extends Component {
	
	render() {
		return (
			<span>
			{
				LANG_NAMES.map((language, i) =>
					<button key={i} onClick={() => this.props.setLanguage(language.locale)}>
						<span>{language.name}</span>
					</button>
				)
			}
			</span>
		);
	}
}
		
LangSwitch.propTypes = {
	locale: PropTypes.string,
	setLanguage: PropTypes.func,
};
