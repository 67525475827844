import React, { Component } from 'react';
import TranslationContainer from './Translation/TranslationContainer';
import LangSwitchContainer from './LangSwitch/LangSwitchContainer';

export default class Header extends Component {

	render() {
		let socials = this.props.socials;

		return (
			<React.Fragment>
				<header id="home">
					<nav id="nav-wrap">
						<a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
						<a className="mobile-btn" href="#" title="Hide navigation">Hide navigation</a>
						<ul id="nav" className="nav">
							<li className="current"><a className="smoothscroll" href="#home">Home</a></li>
							<li><a className="smoothscroll" href="#about"><TranslationContainer translationKey="navAbout"/></a></li>
							<li><a className="smoothscroll" href="#resume"><TranslationContainer translationKey="navResume"/></a></li>
							<li><a className="smoothscroll" href="#portfolio"><TranslationContainer translationKey="navPortfolio"/></a></li>
							<li><a className="smoothscroll" href="#contact"><TranslationContainer translationKey="navContact"/></a></li>
							<li style={{ float: 'right' }}><a className="smoothscroll"><LangSwitchContainer /></a></li>
						</ul>
					</nav>
				
					<div className="row banner">
						<div className="banner-text">
							<h1 className="responsive-headline">Nadir Barlozzo</h1>
							<h3 style={{color:'#fff', fontFamily:'sans-serif '}}><TranslationContainer translationKey="role"/></h3>
							<h4 style={{color:'#8abeff', fontFamily:'sans-serif '}}>C#, NodeJS, EJS6, Typescript, React, Redux, PHP, CSS, Unity <TranslationContainer translationKey="skillsEtc"/></h4>
							<hr/>
							<ul className="social">
							{
								socials && socials.map(item =>{
									return (
										<li key={item.name}>
											<a href={item.url} target="_blank"><i className={item.className}></i></a>
										</li>
									)
								})
							}
							</ul>
						</div>
					</div>
						
					<p className="scrolldown">
						<a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
					</p>
				</header>
			</React.Fragment>
		);
	}
}