export const TRANSLATIONS = {
	it_IT: {
		navAbout: 'A riguardo',
		navResume: 'Riassunto',
		navPortfolio: 'Progetti',
		navContact: 'Contattami', 
		aboutMe: 'Sono un informatico che si diletta principalmente nello sviluppo di applicazioni web ma che rimane sempre aperto a nuove sfide, imporsi nuovi obbiettivi e nuove tecnologie da imparare.',
		hobby: 'Montaggio Video, Tennis, Serie TV, 3D',
		role: 'Sviluppatore Applicazioni Web & Desktop',
		skillsEtc: ' e molto altro.',
		education: 'Titoli di studio',
		work: 'Lavoro',
		hSchoolName: 'Scuola Arti e Mestieri Trevano',
		hSpec: 'Informatico Aziendale AFC + Maturità Professionale',
		hMonthPassing: 'Luglio',
		hYearPassing: '2019',
		hAchievements: '2° posto ex-aequo per i migliori apprendisti informatici 2019',
		mSchoolName: 'Scuole Medie di Trevano',
		mSpec: 'Diploma di scuola media',
		mMonthPassing: 'Luglio',
		mYearPassing: '2015',
		companyName: 'SwissLimbs',
		jobDescription: 'Informatico unico per uno stage di 2 mesi',
		monthLeaving: 'Giugno',
		yearLeaving: '2018',
		companyName2: 'ATAL',
		active: 'attivo',
		jobDescription2: 'Webmaster freelance, sviluppatore web, consulenza informatica',
		jobAchievements2: 'Gestione dell\'infrastruttura front e back-end, sviluppo di soluzioni ad-hoc',
		jobAchievements: 'Vari applicativi web e ricerche tecniche effettuate per progetti aziendali',
		contactMe: 'Non esitare a contattarmi per qualsiasi lavoro o suggerimento di seguito:',
		address: 'Ticino, Svizzera',
		checkOut: 'Guarda alcuni dei miei lavori.'
	},
	en_US: {
		navAbout: 'About',
		navResume: 'Resume',
		navPortfolio: 'Portfolio',
		navContact: 'Contact Me', 
		aboutMe: 'I am a computer scientist who mainly delights in the development of web applications but who always remains open to new challenges, to set new goals and new technologies to learn.',
		hobby: 'Video Editing, Tennis, TV Series, 3D',
		role: 'Web & Desktop Application Developer',
		skillsEtc: ' and lots more.',
		education: 'Education',
		work: 'Work',
		hSchoolName: 'Trevano Arts and Crafts School',
		hSpec: 'AFC Business IT + Professional Baccalaureate',
		hMonthPassing: 'July',
		hYearPassing: '2019',
		hAchievements: '2nd place ex-aequo for the best IT apprentices 2019',
		mSchoolName: 'Trevano Middle School',
		mSpec: 'Middle school diploma',
		mMonthPassing: 'July',
		mYearPassing: '2015',
		companyName: 'SwissLimbs',
		jobDescription: 'Single computer scientist for a 2-month internship',
		monthLeaving: 'June',
		companyName2: 'ATAL',
		active: 'active',
		jobDescription2: 'Webmaster freelancer, web developer, it consulting',
		yearLeaving: '2018',
		jobAchievements: 'Various web applications and technical research carried out for corporate projects',
		jobAchievements2: 'Management of the front and back-end infrastructure, development of ad-hoc solutions',
		contactMe: 'Feel free to contact me for any work or suggestions below:',
		address: 'Ticino, Switzerland',
		checkOut: 'Check out some of my works.'
	}
};

export const LANG_NAMES = [
	{ locale: 'it_IT', name: 'IT' },
	{ locale: 'en_US', name: 'EN' },
]
