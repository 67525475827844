import React, { Component } from 'react';
import TranslationContainer from './Translation/TranslationContainer';

export default class Contact extends Component {

	render() {
		return (
			<section id="contact">
				<div className="row section-head">
					<div className="ten columns">
						<p className="lead"><TranslationContainer translationKey="contactMe"/></p>
					</div>
				</div>
				<div className="row">
					<aside className="eigth columns footer-widgets">
						<div className="widget">
							<span>Nadir Barlozzo</span><br/>
							<span><TranslationContainer translationKey="address"/></span><br/>
							<span>nadir.barlozzo@gmail.com</span><br/>
							<span>+41 079 626 86 76</span><br/>
						</div>
					</aside>
				</div>
			</section>
		);
	}
}